import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <section
        className="about-area p-relative fix"
        style={{ background: "#eff7ff" }}
      >
        <div className="container" style={{ paddingTop: "100px" }}>
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-md-8 col-sm-8">
              <div
                className="s-about-img p-relative  wow fadeInLeft animated"
                data-animation="fadeInLeft"
                data-delay=".4s"
              >
                <img src="assets/img/features/about_img_02.png" alt="img" />
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div
                className="about-content s-about-content pl-15 wow fadeInRight  animated"
                data-animation="fadeInRight"
                data-delay=".4s"
              >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap" /> About Our School
                  </h5>
                  <h2>A Few Words About the School</h2>
                </div>
                <p className="txt-clr">
                  We thank you first for the honour bestowed upon us in
                  considering our school for admission. The school was founded
                  by the beloved Pastor Mr.J.T.Marcus and his devoted wife
                  Mrs.Vijaya Thiagarajan. All Glory and honour to our lord and
                  Saviour Jesus Christ The Ebenezer Marcus Vijoya International
                  School CBSE is to opened to the glory of God in June 2023. The
                  school is Co-Educational and the medium of instruction is
                  English.
                </p>
                <p className="txt-clr">
                  The school is managed by the charitable trust namely Ebenezer
                  Marcus trust. The main objective of the trust is to establish
                  educational institutions of quality education to all citizens.
                  This trust strongly believes that education should develop
                  high moral and social values in children and they should
                  acquire the ability to differentiate what is right and wrong.
                </p>
                <p className="txt-clr">
                  The trust has been in the service of providing quality
                  education for the past 45 years, through two schools (Ebenezer
                  Marcus Matriculation Higher Secondary School & Ebenezer Marcus
                  International School and junior college) in Chennai. The
                  Children who studied in these Schools are placed in higher
                  positions in internationally renowned institutions..
                </p>
                <div className="about-content2">
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="green2">
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>01</span>
                            </div>{" "}
                            <div className="text">
                              <Link
                                to="/projects"
                                state={{ homeCategory: "cbse" }}
                              >
                                <span>Ebenezer CBSE</span>{" "}
                              </Link>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="abcontent">
                            <div className="ano">
                              <span>02</span>
                            </div>{" "}
                            <div className="text">
                              <Link
                                to="/projects"
                                state={{ homeCategory: "Matric" }}
                              >
                                <span>Ebenezer Matric</span>{" "}
                              </Link>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="slider-btn mt-20 mb-30">
                  <Link to="/about" className="btn ss-btn smoth-scroll">
                    Read More <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
