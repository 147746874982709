import React from "react";
import Bredcom from "../../Bredcom/Main";
import Header from "../../headerone/Main";
import First from "../mengallery/First";

function Main() {
  return (
    <>
      <Header />
      <Bredcom
        title="Home"
        subtitle="Our Gallery"
        description={
          <>
            <section className="animations-100">
              Fear Not For I Am With You, I Am Your God I Will Strengthen You.
            </section>
          </>
        }
      />
      <First />
    </>
  );
}

export default Main;
