import React from "react";

function Touch() {
  return (
    <>
      <section id="services" className="services-area pt-120 pb-90 fix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center mb-50 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s"
              >
                <h5>
                  <i className="fal fa-graduation-cap" /> Contact Info
                </h5>
                <h2>Get In Touch</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="services-box text-center">
                <div className="services-icon">
                  <img
                    src="assets/img/bg/contact-icon01.png"
                    alt="Phone Contact"
                  />
                </div>
                <div className="services-content2">
                  <h5>
                    <a href="tel:+914281290365"> +914281290365 </a>
                    <a href="tel:+914281291102"> +914281291102 </a>
                  </h5>
                  <p>Phone Support</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="services-box text-center active">
                <div className="services-icon">
                  <img
                    src="assets/img/bg/contact-icon02.png"
                    alt="Email Contact"
                  />
                </div>
                <div className="services-content2">
                  <h5>
                    <a href="mailto:ebenezermarcusvijoyacbse@gmail.com">
                      ebenezermarcusvijoyacbse@gmail.com
                    </a>
                  </h5>
                  <p>Email Address</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="services-box text-center">
                <div className="services-icon">
                  <img src="assets/img/bg/contact-icon03.png" alt="Address" />
                </div>
                <div className="services-content2">
                  <h5>
                    No.9 &10, Assambur Athiyur road, Manjakuttai Panchayat,
                    Yercaud taluk, Salem Dt
                  </h5>
                  <p>Tamil Nadu </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Touch;
