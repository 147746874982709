import React from "react";
import { Link } from "react-router-dom";

function Blocks() {
  return (
    <>
      <section className="service-details-two p-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="services-box07">
                <div className="sr-contner">
                  <div className="icon">
                    <img src="assets/img/icon/sve-icon4.png" alt="icon01" />
                  </div>
                  <div className="text">
                    <h5>
                      <Link to="/about">Education Services</Link>
                    </h5>
                    <p>
                      It is directed at achieving certain aims, transmitting
                      knowledge or fostering skills and character traits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="services-box07 active">
                <div className="sr-contner">
                  <div className="icon">
                    <img src="assets/img/icon/sve-icon5.png" alt="icon01" />
                  </div>
                  <div className="text">
                    <h5>
                      <Link to="/about">STUDENT LIFE</Link>
                    </h5>
                    <p>
                      The cornerstone of its success is Project Based Learning
                      (PBL) and Personalized Learning Plans (PLP).
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="services-box07">
                <div className="sr-contner">
                  <div className="icon">
                    <img src="assets/img/icon/sve-icon6.png" alt="icon01" />
                  </div>
                  <div className="text">
                    <h5>
                      <Link to="/about">CAMPUS</Link>
                    </h5>
                    <p>
                      Our school is amidst of 10 acres with spacious classrooms,
                      Labs, hygienic restrooms, well stocked libraries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blocks;
