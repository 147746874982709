
export function getContents (curriculum) {
  if (curriculum === 'preprimary') {
      return prePrimaryContent();
  } else if (curriculum === 'primary') {
      return primaryContent();
  }else if (curriculum === 'middle') {
      return middleSchoolContent();
  }
}

const prePrimaryContent = () => {
  return {
      image: "assets/img/bg/pre-primary.png",
      title: "PRE PRIMARY",
      desc: "The Pre-Primary World of Fun for Pre-kg to UKG Classes",
      intro: `
          For Pre-Primary group, we emphasize on three core areas for skill development:
          1.	Interpersonal Skills development
          2.	Intrapersonal Skills development
          3.	Psychological development        
      `,
      content: [
          {
              contentHeading: "Concept Learning",
              contentDesc: `A particular concept is chosen and everything related to that concept is taught to kids 
              through games and other activities. For example concept of healthy living, kids are told everything 
              related to be healthy like hygiene, exercising, healthy food etc. This activity helps in imparting general
              knowledge to kids in a fun loving way.`
          },
          {
              contentHeading: "Playing with Blocks",
              contentDesc: `Blocks come an excellent play school educational activity because blocks help children 
              learn various colors, shapes, dimensions even as they begin to understand what towers are or what buildings 
              or cars or roads are. Besides this, blocks help children understand what balancing, shapes, patterns, are all about. 
              We have different kinds of block games wherein kids apply their own logic to come out with some meaningful structure.`
          },
          {
              contentHeading: "Art & Craft work",
              contentDesc: `Craft and art work helps to invoke creativity, imagination and observation in kids. 
              We encourage kids to do different kinds of art and craft activities like paper craft, clay modeling etc. 
              Through these activities we make them learn different kinds of textures. These activities help to make them 
              innovative and creative`
          }
      ]

  }
};

const primaryContent = () => {
  return {
    image: "assets/img/bg/couress-img-2.JPG",
      title: "PRIMARY",
      desc: "The Primary World of Fun for I to V Classes",
      intro: `
              We follow NCERT/CBSE syllabus in the primary school. 
              The focus of primary school will be on different areas of development as language building, reading readiness, 
              arithmetic and analytical skills. Many kids join from different play schools in class 1st which follow different 
              syllabi. So the curriculum of class 1st is designed in a way that ensures that kids joining us after completing 
              UKG start seamlessly. The children in primary classes go through orientation classes for first three weeks to 
              make them adept to teaching methodology and then instructors gently guide them to start on regular academic activities.
      `,
      content: [
          {
              contentHeading: "English curriculum",
              contentDesc: `English curriculum is central to the development program in primary classes. 
              Reading, writing, speaking and comprehension are essential basic language skills for the students and 
              most important development area for primary classes.`
          },
          {
              contentHeading: "Mathematics curriculum",
              contentDesc: `Mathematics in primary classes is more of solving practical problems in day to day life. 
              These include concept of numbers, time, measurement, fractions, shapes etc. To make children learn and 
              appreciate these basic mathematical concepts, best way is to help them in solving problems related to 
              these concepts. We follow the approach just outlined for teaching mathematics in primary classes.`
          },
          {
              contentHeading: "Sciences curriculum",
              contentDesc: `The areas covered in this category relate to study of basic natural sciences and social sciences. 
              As part of social science curriculum, we emphasize on the importance of individual in the society as a 
              whole and his rights and duties as an individual.`
          }
      ]
  }
};

const middleSchoolContent = () => {
  return {
    image: "assets/img/bg/couress-img-3.png",
      title: "Middle School",
      desc: "The Primary World of Fun for VI to VI Classes",
      intro: `
          We follow NCERT/CBSE syllabus in the primary school. The focus of primary school will be on different areas of development 
          as language building, reading readiness, arithmetic and analytical skills. The curriculum consists of work sheets based on 
          syllabus for the class with the difficulty level increasing gradually over the academic session. Appropriate weightage is 
          given to continuous evaluation (out of total) which uniformly distributes the academic load across the full year session.
      `,
      content: [
          {
              contentHeading: "English curriculum",
              contentDesc: `To aid in developing language skills we follow host of activities like book reading, 
              poetry/songs recitation, story-telling, role play, short story writing etc. As our teaching methodology 
              stresses on classroom discussions for learning concepts, this in itself will be the best means for their 
              language development.`
          },
          {
              contentHeading: "Mathematics curriculum",
              contentDesc: `Firm grounding in these basic mathematical concepts learnt in middle classes form the 
              basis of understanding the higher concepts like logical thinking in high school.To make children learn
              and appreciate these basic mathematical concepts, best way is to help them in solving problems related 
              to these concepts`
          },
          {
              contentHeading: "Sciences curriculum",
              contentDesc: `For science part, it is more to do with the observation of surroundings by children, 
              of experimenting in lab and hypothesizing to understand the universe and its laws. Children spend quality 
              time in lab trying to understand the basics of natural science. To understand mother earth and nature by 
              studying natural phenomena and living things around us form integral part of science curriculum.`
          },
          {
              contentHeading: "Information Technology",
              contentDesc: `Computing devices are omnipresent nowadays. After all we are living in a computer age!!.`
          },
          {
              contentHeading: "Physical Education",
              contentDesc: `Physical education consists of spending time playing sports and doing physical activity under 
              supervision of an instructor. Lot of interactions happens while kids are engaged in team sports. 
              Sports play an important part in personality development of young ones as they learn from each other, 
              adapt to varying situations and make strategies while playing in a group. Sports enhance innate leadership 
              qualities and children learn the spirit of life is not just winning but participation is equally important.`
          }
      ]
  }
};