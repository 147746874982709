import React from "react";
import Header from "../headerone/Main";
import Bredcom from "../Bredcom/Main";
function Main() {
  return (
    <>
      <Header />
      <Bredcom
        title="Home"
        subtitle="Affiliation"
        description={
          <>
            <section className="animations-100">
              Give instruction to a wise man, and he will be still wiser; teach
              a righteous man, and he will increase in learning
            </section>
          </>
        }
      />
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row" style={{ overflowX: "auto" }}>
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h3>MANDATORY PUBLIC DISCLOSURE</h3>
                <h5>A.GENERAL INFORMATION</h5>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Information</th>
                        <th scope="col">Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>NAME OF THE SCHOOL</td>
                        <td>
                          EBENEZER MARCUS VIJOYA INTERNATIONAL SCHOOL CBSE
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>AFFILIATION NO</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>SCHOOL CODE</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>COMPLETE ADDRESS WITH PIN CODE</td>
                        <td>
                          NO.9,10 ASAMBUR & ATHIYUR VILLAGE, MANJAKUTTAI
                          PANCHAYAT, YERCAUD SALEM, SALEM , TAMILNADU - 636601
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>PRINCIPAL NAME AND QUALIFICATION </td>
                        <td>Mrs. VIJAYA THIAGARAJAN M.A.,M.ED</td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>SCHOOL EMAIL ID </td>
                        <td>ebenezermarcusvijoyacbse@gmail.com</td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>CONTACT DETAILS </td>
                        <td>9840042465, +914281290365, +914281291102</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>
                  B.DOCUMENTS AND INFORMATION :
                </h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">DOCUMENTS / INFORMATION</th>
                      <th scope="col">DOCUMENTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>
                        COPIES OF AFFILIATION / UPGRADATION LETTER AND RECENT
                        EXTENSION OF AFFILIATION IF ANY
                      </td>
                      <td>Annexure 1</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>
                        COPIES OF SOCIETIES / TRUST / COMPANY REGISTRATION /
                        RENEWAL CERTIFICATE, AS APPLICABLE
                      </td>
                      <td>Annexure 2</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>
                        COPIES OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF
                        APPLICABLE BY THE STATE GOVT / UT
                      </td>
                      <td>Annexure 3</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>
                        COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009
                        AND ITS RENEWAL IF APPLICABLE
                      </td>
                      <td>Annexure 4</td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>
                        COPY OF VALID BUILDING CERTIFICATE AS PER THE NATIONAL
                        BUILDING CODE
                      </td>
                      <td>Annexure 5</td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>
                        COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE
                        COMPETENT AUTHORITY
                      </td>
                      <td>Annexure 6</td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>
                        COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR
                        AFFILIATION / UPGRADATION / EXTENSION OF AFFILIATION OR
                        SELF CERTIFICATION BY SCHOOL
                      </td>
                      <td>Annexure 7</td>
                    </tr>
                    <tr>
                      <th scope="row">8</th>
                      <td>
                        COPIES OF WATER, HEALTH AND SANITATION CERTIFICATES
                      </td>
                      <td>Annexure 8</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>C.RESULTS AND ACADEMICS</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">DOCUMENTS / INFORMATION</th>
                      <th scope="col">DOCUMENTS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>FEE STRUCTURE OF THE SCHOOL (PER MONTH)</td>
                      <td>Annexure 9</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>ANNUAL ACADEMIC CALENDER Annexure 10</td>
                      <td>Annexure 10</td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>
                        LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC) Annexure 11
                      </td>
                      <td>Annexure 11</td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>
                        LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS
                      </td>
                      <td>Annexure 12</td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>
                        LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER
                        APPLICABILITY
                      </td>
                      <td>Annexure 13</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>D.STAFF (TEACHING)</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">INFORMATION</th>
                      <th scope="col">DETAILS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>PRINCIPAL</td>
                      <td>Mrs. VIJAYA THIAGARAJAN</td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>TOTAL NO. OF TEACHERS</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>PGT</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>TGT</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>PRT</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row"></th>
                      <td>NTT</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>TEACHERS SECTION RATIO</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>DETAILS OF SPECIAL EDUCATOR</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>DETAILS OF COUNSELLOR AND WELLNESS TEACHER</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h5 style={{ marginTop: "40px" }}>E.SCHOOL INFRASTRUCTURE</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">INFORMATION</th>
                      <th scope="col">DETAILS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>TOTAL CAMPUS AREA OF THE SCHOOL (IN SQ. MTR)</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>NO. AND SIZE OF THE CLASS ROOMS (IN SQ. MTR)</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>
                        NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN
                        SQ. MTR)
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <td>INTERNET FACILITY</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">5</th>
                      <td>NO. OF GIRLS TOILETS</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">6</th>
                      <td>NO. OF BOYS TOILETS</td>
                      <td></td>
                    </tr>
                    <tr>
                      <th scope="row">7</th>
                      <td>
                        LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL
                        COVERING THE INFRASTURCTURE OF THE SCHOOL
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Main;
