import React from "react";

function Frequently() {
  return (
    <>
      <section className="faq-area pt-120 pb-120 p-relative fix">
        <div className="animations-10">
          <img src="assets/img/bg/an-img-04.png" alt="an-img-01" />
        </div>
        <div className="animations-08">
          <img src="assets/img/bg/an-img-05.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row justify-content-center  align-items-center">
            <div className="col-lg-7">
              <div
                className="section-title wow fadeInLeft animated"
                data-animation="fadeInDown animated"
                data-delay=".2s"
              >
                <h2>INFORMATION</h2>
                <p>Ebenezer Marcus School With Classes From Nursery To VI</p>
              </div>
              <div
                className="faq-wrap mt-30 pr-30 wow fadeInUp animated"
                data-animation="fadeInUp"
                data-delay=".4s"
              >
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="faq-btn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                        >
                          Admission Information
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse show"
                      data-bs-parent="#accordionExample"
                    >
                      <p>
                        Fresh Admissions Are Made Only For Pre-kg TO Standard
                        VI. Selection Are Made Through An Entrance Examination.
                      </p>
                      <p>
                        At The Time Of Admission The Following Copies Of The
                        Document Must Be Produced In Originals.
                      </p>
                      <ul className="list-group">
                        <li className="list-group-item">
                          Transfer Certificate
                        </li>
                        <li className="list-group-item">Birth Certificate</li>
                        <li className="list-group-item">
                          Community Certificate
                        </li>
                        <li className="list-group-item">
                          Conduct Certificate(From The Head Of The Institution
                          Last Studied)
                        </li>
                        <li className="list-group-item">
                          A Passport Size Photo
                        </li>
                        <li className="list-group-item">Mark Sheet</li>
                        <li className="list-group-item">
                          Residential Proof- Xerox Of Ration Card, Bank Pass
                          Book
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="faq-btn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Course Timings
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Class</th>
                            <th scope="col">Timing</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">1</th>
                            <td>LKG</td>
                            <td>8.15 A.M. to 12.00 Noon</td>
                          </tr>
                          <tr>
                            <th scope="row">2</th>
                            <td>UKG - Class II</td>
                            <td>8.15 A.M. to 2.30 P.M.</td>
                          </tr>
                          <tr>
                            <th scope="row">3</th>
                            <td>Class III - V</td>
                            <td>8.15 A.M to 3.00 P.M </td>
                          </tr>
                          <tr>
                            <th scope="row">4</th>
                            <td>Class VI</td>
                            <td>8.15 A.M to 3.45 P.M</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Frequently;
