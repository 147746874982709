import React from 'react'

function Shep() {
  return (
    <>
        <section className="steps-area p-relative" style={{ backgroundColor: "#032e3f" }} >
            <div className="animations-10">
                <img src="assets/img/bg/an-img-10.png" alt="an-img-01" />
            </div>
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                    <div className="section-title mb-35 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                        <h2>Our Best Features</h2>
                    </div>
                    <ul className="pr-20">
                    <li>
                        <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="dnumber">
                            <div className="date-box">
                                <img src="assets/img/icon/fea-icon01.png" alt="icon" />
                            </div>
                        </div>
                        <div className="text">
                            <h3>Skilled Teachers</h3>
                            <p>
                            Our teachers provide special education to students with learning activities and experiences designed to 
                            help them fulfill their potential for intellectual, emotional, physical, and social growth. 
                            </p>
                        </div>
                        </div>
                    </li>
                    <li>
                        <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="dnumber">
                            <div className="date-box">
                                <img src="assets/img/icon/fea-icon02.png" alt="icon" />
                            </div>
                        </div>
                        <div className="text">
                            <h3>Affordable Courses</h3>
                            <p>
                            We offer affordable and also quality education from Pre-school to Std VI. 
                            Our mission is to “empower every student to achieve full potential. 
                            We provide academics with the social objective of making high quality technology based learning 
                            content available to the students.
                            </p>
                        </div>
                        </div>
                    </li>
                    <li>
                        <div className="step-box wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="dnumber">
                            <div className="date-box">
                                <img src="assets/img/icon/fea-icon03.png" alt="icon" />
                            </div>
                        </div>
                        <div className="text">
                            <h3>Infrastructure</h3>
                            <p>
                            Building a good environment is necessary to make kids feel safe and taken care of while they grow and learn. 
                            The School have enough spacious and refurbished buildings, well ventilated classrooms and also useful facilities like libraries, 
                            toilets, playground, multipurpose rooms, work areas, laboratories etc.
                            <br/>
                            <strong>Smart class rooms:</strong> The backbone of any school infrastructure is the classroom. All the classrooms are well furnished with smart boards etc

                            </p>
                        </div>
                        </div>
                    </li>
                    </ul>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="step-img wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                        <img src="assets/img/bg/steps-img.gif" alt="steps-img" />
                    </div>
                </div>
                </div>
            </div>
        </section>

    </>
  )
}

export default Shep