import React from "react";

function First({ props }) {
  const courseContents = props.courseContents;
  console.log(courseContents);
  return (
    <>
      <section className="project-detail">
        <div className="container">
          <div className="lower-content">
            <div className="row">
              <div className="text-column col-lg-12 col-md-12 col-sm-12">
                <h2>{courseContents.desc}</h2>
                <div className="upper-box">
                  <div className="single-item-carousel owl-carousel owl-theme">
                    <figure className="image">
                      <img src={courseContents.image} alt="" />
                    </figure>
                  </div>
                </div>
                <div className="inner-column">
                  <h3>Course Overview</h3>
                  <p>{courseContents.intro}</p>
                  {courseContents.content.map((con, i) => {
                    return (
                      <>
                        <h4 key={"h" + i}>{con.contentHeading}</h4>
                        <p key={"p" + i}>{con.contentDesc}</p>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
