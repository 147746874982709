import React from 'react'
import { useLocation } from 'react-router-dom'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencoursedetails/First'
import { getContents } from './content'
function Main() {

  
  const location = useLocation();
  const courseContents = getContents(location.state.level)
  return (
    <>
        <Header/>
        <Bredcom 
          title="Home" 
          subtitle= {courseContents.title} 
          description={
            <>
              <section className="animations-100">
                Fear Not For I Am With You, I Am Your God I Will Strengthen You.
              </section>
            </>
          }
          />
        <First props = {{courseContents}}/>
    </>
  )
}

export default Main