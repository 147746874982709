import React from 'react'
import { Link } from 'react-router-dom'

function Main() {
  return (
    <>
        <footer className="footer-bg footer-p pt-20" style={{ backgroundColor: "#125875", backgroundImage: "url(assets/img/bg/footer-bg.png)" }} >
            <div className="footer-top pb-20">
                <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xl-8 col-lg-8 col-sm-6">
                    <div className="footer-widget mb-30">
                        <div className="f-widget-title">
                        <h2>About Us</h2>
                        </div>
                        <div className="f-contact">
                        <p>
                            Our vision is to develop well rounded, confident and responsible individuals who aspire to achieve 
                            their full potential. We will do this by providing a welcoming, happy, safe, and supportive learning 
                            environment in which everyone is equal and all achievements are celebrated
                        </p>
                        </div>
                    </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-6">
                    <div className="footer-widget mb-30">
                        <div className="f-widget-title">
                        <h2>Contact Us</h2>
                        </div>
                        <div className="f-contact">
                        <ul>
                            <li>
                            <i className="icon fal fa-phone" />
                            <span>
                            <strong>
                            <Link to="tel:+914281290365">+914281290365 </Link>
                            <Link to="tel:+914281291102">+914281291102 </Link>
                          </strong>
                            </span>
                            </li>
                            <li>
                            <i className="icon fal fa-envelope" />
                            <Link to="mailto:ebenezermarcusvijoyacbse@gmail.com">
                              ebenezermarcusvijoyacbse@gmail.com
                            </Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="copy-text">
                        <Link to="/">
                            <img src="assets/img/logo/logo.png" alt="img" />
                        </Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Main