import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "../component/About/Main";
import Pricing from "../component/Pages/Pricing/Main";
import Error from "../component/Pages/error/Main";
import Gallery from "../component/Pages/gallery/Main";
import Contact from "../component/contact/Main";
import Footer from "../component/footer/Main";
import Home from "../component/home/Main";
import Coursesdeatils from '../component/coursedetails/Main';
import Affiliation from '../component/affiliation/Main';

function Index() {
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Gallery />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/404-error" element={<Error />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/single-courses" element={<Coursesdeatils/>} /> 
        <Route path="/affiliation" element={<Affiliation/>} /> 
      </Routes>

      <Footer />
    </>
  );
}

export default Index;
