import React from 'react'

function First() {
  return (
    <>
        <section id="pricing" className="pricing-area pt-150 pb-60 fix p-relative">
            <div className="container">
                <div className="row">
                <div className="col-lg-12 col-md-12">
                <div className="pricing-body mt-20 mb-30 text-center">
                        Online payment is under consideration and will be made available for your use at earliest.
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First