import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>
      
    );
}

function Courses() {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: <SamplePrevArrow/>,
		nextArrow: <SampleNextArrow/>,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
      };

      const coursesData = [
        {
            id: 1,
            level: "preprimary",
            imagePath: "assets/img/bg/couress-img-1.JPG",
            imageText: "PRE PRIMARY",
            desc: "The Pre-Primary World of Fun for Pre-kg to UKG Classes"
        },
        {
            id: 2,
            level: "primary",
            imagePath: "assets/img/bg/couress-img-2.JPG",
            imageText: "PRIMARY",
            desc: "The Primary World of Fun for I to V Classes"
        },
        {
            id: 3,
            level: "middle",
            imagePath: "assets/img/bg/couress-img-3.png",
            imageText: "MIDDLE",
            desc: "The Primary World of Fun for VI Classes"
        }       
      ];


  return (
    <>
        <section className="courses p-relative fix">
            <div className="animations-01">
                <img src="assets/img/bg/an-img-03.png" alt="an-img-01" />
            </div>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 p-relative">
                    <div className="section-title center-align mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                        <h5>
                            <i className="fal fa-graduation-cap" /> Our Courses
                        </h5>
                        <h2>ACADEMICS</h2>
                    </div>
                </div>
                </div>
                <Slider className="row class-active" {...settings}>
                    {
                         coursesData.map((data) => {
                            return <div key={data.id} className="col-lg-4 col-md-6 ">
                            <div className="courses-item mb-30 hover-zoomin">
                                <div className="thumb fix">
                                    <Link to="/single-courses" state={{level: data.level}}>
                                        <img src={data.imagePath} alt="contact-bg-an-01" />
                                    </Link>
                                </div>
                                <div className="courses-content">
                                    <div className="cat">
                                    <i className="fal fa-graduation-cap" /> {data.imageText}
                                    </div>
                                    <h3>
                                    <Link to="/single-courses" state={{level: data.level}}> {data.desc} </Link>
                                    </h3>
                                    <Link to="/single-courses" state={{level: data.level}} className="readmore">
                                    Read More <i className="fal fa-long-arrow-right" />
                                    </Link>
                                </div>
                                <div className="icon">
                                    <img src="assets/img/icon/cou-icon.png" alt="img" />
                                </div>
                            </div>
                        </div>
                        })
                    }
                </Slider>
            </div>
        </section>
    </>
  )
}

export default Courses