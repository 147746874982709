import React from "react";
import Bredcom from "../Bredcom/Main";
import Headerone from "../headerone/Main";
import About from "../menhome/About";
import Courses from "../menhome/Courses";
import Frequently from "../menhome/Frequently";
import Shep from "../menhome/Shep";

function Main() {
  return (
    <>
      <Headerone />
      {/* <Slider /> */}
      <Bredcom
        title="Home"
        description={
          <>
            <section className="animations-100">
              Give instruction to a wise man, and he will be still wiser; teach
              a righteous man, and he will increase in learning
            </section>
          </>
        }
      />
      <About />
      <Courses />
      <Shep />
      <Frequently/>
    </>
  );
}

export default Main;
