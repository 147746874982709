import React from 'react'
import Bredcom from '../Bredcom/Main'
import University from '../Menabout/University'
import Header from '../headerone/Main'
import Blocks from '../Menabout/Blocks'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom
        title="Home" 
        subtitle="About Us"
        description={
          <>
            <section className="animations-100"> 
                I WILL HELP YOU. I WILL UPHOLD YOU WITH MY RIGHTEOUS RIGHT HAND.
            </section>
          </>
        }
        />
        <Blocks/>
        <University/>
    </>
  )
}

export default Main