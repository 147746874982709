import React, { useState } from "react";
import { refreshGalleryActive } from "./galleryContent";
import { useLocation } from "react-router-dom";

function First() {
  const location = useLocation();
  const constMatric = location?.state?.homeCategory
    ? location.state.homeCategory
    : undefined;

  const Data = [
    { img: "assets/img/gallery/ph1.JPG", category: "PrayerHall" },
    { img: "assets/img/gallery/ph2.JPG", category: "PrayerHall" },
    { img: "assets/img/gallery/ph3.JPG", category: "PrayerHall" },

    { img: "assets/img/gallery/sc1.JPG", category: "School" },
    { img: "assets/img/gallery/sc2.JPG", category: "School" },
    { img: "assets/img/gallery/sc3.JPG", category: "School" },
    { img: "assets/img/gallery/sc4.JPG", category: "School" },

    { img: "assets/img/gallery/lab1.jpeg", category: "Lab" },
    { img: "assets/img/gallery/lab2.jpeg", category: "Lab" },
    { img: "assets/img/gallery/lab3.jpeg", category: "Lab" },

    { img: "assets/img/gallery/cr1.JPG", category: "ClassRoom" },
    { img: "assets/img/gallery/cr2.JPG", category: "ClassRoom" },

    { img: "assets/img/gallery/lib1.jpeg", category: "Library" },

    { img: "assets/img/gallery/matric1.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric2.jpg", category: "Matric" },
    { img: "assets/img/gallery/matric4.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric5.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric6.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric7.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric8.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric9.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric10.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric11.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric12.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric13.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric14.JPG", category: "Matric" },
    { img: "assets/img/gallery/matric16.JPG", category: "Matric" },

    { img: "assets/img/gallery/cbse1.jpg", category: "cbse" },
    { img: "assets/img/gallery/cbse2.JPG", category: "cbse" },
    { img: "assets/img/gallery/cbse4.JPG", category: "cbse" },
    { img: "assets/img/gallery/cbse5.JPG", category: "cbse" },
    { img: "assets/img/gallery/cbse6.JPG", category: "cbse" },
    { img: "assets/img/gallery/cbse7.JPG", category: "cbse" },
  ];

  const filterValue = (cat) => Data.filter((item) => item.category === cat);

  const filteredData = constMatric ? filterValue(constMatric) : Data;
  const activeMatric = constMatric === "Matric" ? "active" : "";
  const activeCbse = constMatric === "cbse" ? "active" : "";
  const activeVa = !constMatric ? "active" : "";

  const [current, setcurrent] = useState(filteredData);
  const [ph, setPh] = useState("");
  const [sc, setSc] = useState("");
  const [lab, setLab] = useState("");
  const [cr, setCr] = useState("");
  const [lib, setLib] = useState("");
  const [matric, setMatric] = useState(activeMatric);
  const [cbse, setCbse] = useState(activeCbse);
  const [va, setVa] = useState(activeVa);

  const refreshActive = (cat) => {
    refreshGalleryActive(cat, {
      setPh,
      setSc,
      setLab,
      setCr,
      setLib,
      setMatric,
      setCbse,
      setVa,
    });
  };

  const Filter = (category) => {
    refreshActive(category);
    if (category === "View All") {
      setcurrent(Data);
    } else {
      setcurrent(filterValue(category));
    }
  };

  return (
    <>
      <section id="work" className="pt-150 pb-105">
        <div className="container">
          <div className="portfolio ">
            <div className="row align-items-end">
              <div className="col-lg-12">
                <div
                  className="my-masonry text-center mb-50 wow fadeInRight  animated"
                  data-animation="fadeInRight"
                  data-delay=".4s"
                >
                  <div className="button-group filter-button-group ">
                    <button className={va} onClick={() => Filter("View All")}>
                      {" "}
                      View All{" "}
                    </button>
                    <button className={ph} onClick={() => Filter("PrayerHall")}>
                      Prayer Hall
                    </button>
                    <button className={sc} onClick={() => Filter("School")}>
                      School
                    </button>
                    <button className={lab} onClick={() => Filter("Lab")}>
                      Lab{" "}
                    </button>
                    <button className={lib} onClick={() => Filter("Library")}>
                      Library
                    </button>
                    <button className={cr} onClick={() => Filter("ClassRoom")}>
                      Class Room
                    </button>
                    <button className={matric} onClick={() => Filter("Matric")}>
                      Matriculation school
                    </button>
                    <button className={cbse} onClick={() => Filter("cbse")}>
                      CBSE school
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="grid col3 row wow fadeInUp  animated"
              data-animation="fadeInUp"
              data-delay=".4s"
            >
              {current.map(
                (item, Index) =>
                  item && (
                    <div className="grid-item col-4" key={Index}>
                      <img src={item.img} alt="img" className="img" />
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
