export const refreshGalleryActive = (cat, {setPh, setSc, setLab, setCr, setLib, setMatric, setCbse, setVa}) => {
    if (cat === "PrayerHall") {
      setPh("active");
      setCr("");
      setLab("");
      setSc("");
      setLib("");
      setVa("");
      setMatric("");
      setCbse("");
    } else if (cat === "School") {
      setPh("");
      setCr("");
      setLab("");
      setSc("active");
      setLib("");
      setVa("");
      setMatric("");
      setCbse("");
    } else if (cat === "Lab") {
      setPh("");
      setCr("");
      setLab("active");
      setSc("");
      setLib("");
      setVa("");
      setMatric("");
      setCbse("");
    } else if (cat === "ClassRoom") {
      setPh("");
      setCr("active");
      setLab("");
      setSc("");
      setLib("");
      setVa("");
      setMatric("");
      setCbse("");
    } else if (cat === "Library") {
      setPh("");
      setCr("");
      setLab("");
      setSc("");
      setLib("active");
      setVa("");
      setMatric("");
      setCbse("");
    } else if (cat === "Matric") {
      setMatric("active");
      setPh("");
      setCr("");
      setLab("");
      setSc("");
      setLib("");
      setVa("");
      setCbse("");
    } else if (cat === "cbse") {
      setMatric("");
      setPh("");
      setCr("");
      setLab("");
      setSc("");
      setLib("");
      setVa("");
      setCbse("active");
    } else {
      setVa("active");
      setPh("");
      setCr("");
      setLab("");
      setSc("");
      setLib("");
    }
  };