import React from 'react'

function University() {
  return (
    <>
        <section className="about-area about-p pt-120 pb-120 p-relative fix">
            <div className="animations-02">
                <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
            </div>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                    <div className="about-title second-title pb-25">
                        <h5>
                        <i className="fal fa-graduation-cap" /> About Our School
                        </h5>
                        <h2>A Few Words About the School</h2>
                    </div>
                    <p className="txt-clr">
                    <strong>VISION:</strong>
                        Our vision is to develop well rounded, confident and responsible individuals who aspire to achieve their full 
                        potential. We will do this by providing a welcoming, happy, safe, and supportive learning environment in which 
                        everyone is equal and all achievements are celebrated.
                    <br/>
                    <br/>
                   
                    <strong>MISSION:</strong>
                        Our Mission is to provide high quality education and childcare in a safe, respectful and inclusive environment 
                        that builds a foundation for life-long learning.
                    <br/>
                    <br/>
                    <strong>Objectives:</strong>
                        The School Aims At Disciplining Body, Mind And Spirit Of The Students.
                        We Believe Not Only In Educational Development But Also Mental, Emotional, Spiritual, And Physical Growth.
                        For any structure the foundation has to be strong enough to build further.
                        We enable the students to strengthen their academic foundation and inculcate a sense of all round discipline 
                        by creating a salubrious environment that kindles knowledge and brings an insight to the promising academic 
                        enlightenment in the minds of the students. There by we aim to build inquisitive and analytical thinking process 
                        that helps the students to hone their skills and develop the competitive spirits to reach greater heights in future.
                        <br/>
                        <br/>
                        The students seeks to work for the total development of the young. The young minds are molded to discover their 
                        God given talents and work towards their fullest development in order to place them at the service of the society. 
                        It seeks to enable the young to search for the truth by training them to analyse reality, form critical judgments, 
                        search for solutions and work out a synthesis. It is concerned with forming a moral conscience that is capable of 
                        discerning what is good and choosing it.
                    </p>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default University