import React from "react";
import { Link } from "react-router-dom";

function Main({ subtitle, title, description }) {
  return (
    <>
      <section
        className="breadcrumb-area d-flex  p-relative align-items-center"
        style={{ backgroundImage: "url(../assets/img/slider/ebe_yercaud.png)" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7 col-sm-7 col-10">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  {/* <h2> {description} </h2> */}
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> {title} </Link>
                  </li>
                  {subtitle && (
                    <li className="breadcrumb-item active" aria-current="page">
                      {subtitle}
                    </li>
                  )}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Main;
