import React from "react";
import Bredcom from "../Bredcom/Main";
import Header from "../headerone/Main";
import Touch from "../mencontact/Touch";

function Main() {
  return (
    <>
      <Header />
      <Bredcom
        title="Home"
        subtitle="Contact Us"
        description={
          <>
            <div className="animations-100">
            For the protection of wisdom is like the protection of money, and the advantage of knowledge is 
            that wisdom preserves the life of him who has it
            </div>
          </>
        }
      />
      <Touch />
      {/* <Map /> <Form />*/}
    </>
  );
}

export default Main;
